<template>
  <div class="p-4 bg-dark-gray text-almost-white">
    <h2 class="font-lexend text-gold text-xl font-bold">{{ heading }}</h2>
    <p
      v-for="(paragraph, index) in content"
      :key="index"
      :class="{ 'pb-4': index < content.length - 1 }"
      class="font-work-sans text-lg font-light"
    >
      {{ paragraph }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SectionWithHeading",
  props: {
    heading: {
      type: String as PropType<string>,
      required: true,
    },
    content: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
</script>
