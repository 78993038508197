<template>
  <div>
    <div
      v-if="product"
      class="flex flex-col md:flex-row justify-center items-start p-4 bg-dark-gray text-almost-white"
    >
      <!-- Left column for images -->
      <div
        class="w-full md:w-2/3 flex flex-col md:flex-row p-4 items-start mt-4 md:mt-0"
      >
        <!-- Main product image -->
        <div class="w-full md:w-3/4 h-64 md:h-[40rem] overflow-hidden">
          <transition name="fade" mode="out-in">
            <ProgressiveImage
              :key="selectedImage"
              :placeholder="getImageForProduct(selectedImage, true)"
              :image="getImageForProduct(selectedImage)"
              alt=""
              transitionDuration="0s"
              class="w-full h-full object-cover bg-[#121212]"
              @click="handleOpenFullscreen(selectedImageIndex)"
            />
          </transition>
        </div>
        <!-- Sub images in a scrollable column -->
        <div
          class="flex flex-row md:flex-col md:w-1/4 mt-4 md:mt-0 md:max-h-[40rem] overflow-auto px-1 md:px-4 py-1 space-x-4 md:space-x-0 md:space-y-4"
        >
          <ProgressiveImage
            v-for="(image, index) in product.images"
            :key="index"
            :placeholder="getImageForProduct(image, true)"
            :image="getImageForProduct(image)"
            alt=""
            :class="{
              'w-20 h-20 md:w-full md:h-20 object-cover cursor-pointer md:mr-0 transition duration-300 ease-in-out transform hover:scale-105 bg-[#121212]': true,
              'ring-2 ring-gold': selectedImage === image,
            }"
            @click="selectImage(index)"
          />
        </div>
      </div>

      <div class="w-full md:w-1/3 p-4 space-y-4">
        <div class="border-b-2 pb-4 border-gold">
          <h1 class="text-4xl font-bold font-bebas">{{ product.name }}</h1>
          <p class="text-lg font-work-sans font-light">
            Cena za kus od {{ product.price }} Kč
          </p>
        </div>
        <div class="pb-8">
          <ul class="list-none space-y-4 border-gold">
            <h2 class="text-gold text-2xl font-lexas font-bold">O produktu</h2>
            <li
              v-for="detail in product.about"
              :key="detail"
              class="flex items-center font-work-sans font-light"
            >
              <img
                src="@/assets/logo/classic_small.png"
                alt=""
                class="w-4 h-4 mr-2"
              />
              <span>{{ detail }}</span>
            </li>
          </ul>
        </div>
        <div class="border-t-2 border-gold pt-8">
          <h2 class="text-xl font-bold font-lexas text-gold">
            Popusťte uzdu své kreativitě
          </h2>
          <p class="text-base font-work-sans font-light text-almost-white">
            Vlastní vzor, barva švů či kůže nebo dokonce úplně jiný tvar? To
            není žádný problém!
          </p>
          <div class="flex justify-center m-8">
            <RegularButton
              buttonText="Chci svůj výrobek"
              :isGold="true"
              to="/nezavazna-poptavka"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else>Loading product details...</div>

    <FullscreenImageViewer
      :visible="isFullscreenVisible"
      :imageSrc="fullscreenImage || ''"
      @close="handleCloseFullscreen"
      @next="nextImage"
      @prev="prevImage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import productsData from "@/data/products.json";
import RegularButton from "@/components/RegularButton.vue";
import ProgressiveImage from "@/components/ProgressiveImage.vue";
import FullscreenImageViewer from "@/components/FullscreenImageViewer.vue";
import { useFullscreenGallery } from "@/composables/useFullscreenGallery";
import { getImagePath } from "@/utils/imageUtils";
import { Product } from "@/types/Product";

const context = require.context("@/assets/images/product", true, /\.jpg$/);

const route = useRoute();
const product = ref<Product | null>(null);
const selectedImage = ref<string>("");

const selectedImageIndex = computed(() => {
  return product.value?.images.indexOf(selectedImage.value) || 0;
});

const {
  fullscreenImage,
  openFullscreen,
  closeFullscreen,
  nextImage,
  prevImage,
  updateGalleryImages,
  isFullscreenVisible,
} = useFullscreenGallery(
  [],
  [],
  { enableKeyboardNavigation: true },
  context,
  product.value?.id || "",
);

watch(
  () => route.params.productName,
  () => {
    const foundProduct = productsData.find(
      (p) => p.id === route.params.productName,
    );
    if (foundProduct) {
      product.value = foundProduct;
      selectedImage.value = foundProduct.images[0];
      updateGallery(foundProduct.id);
    }
  },
  { immediate: true },
);

function getImageForProduct(imageName: string, isPlaceholder = false) {
  const productId = product.value?.id || "";
  return getImagePath(context, imageName, isPlaceholder, productId);
}

function selectImage(index: number) {
  selectedImage.value = product.value?.images[index] || "";
}

function updateGallery(productId: string) {
  if (product.value) {
    updateGalleryImages(
      product.value.images.map((image) => ({
        image: `${productId}/${image}`,
      })),
    );
  }
}

function handleOpenFullscreen(index: number) {
  openFullscreen(index);
}

function handleCloseFullscreen() {
  closeFullscreen();
}
</script>
