<template>
  <footer class="bg-black text-gold border-t border-gold shadow-lg flex">
    <div
      class="max-w-screen-xl mx-auto py-4 px-3 sm:px-4 md:py-8 md:px-6 lg:px-8 grid md:grid-cols-2 gap-16 md:gap-32"
    >
      <div class="flex flex-col space-y-4 md:text-right">
        <router-link
          to="/kontakty"
          class="text-sm sm:text-base hover:text-white transition-colors duration-300 underline"
          >Kontakty</router-link
        >
        <router-link
          to="/obchodni-podminky"
          class="text-sm sm:text-base hover:text-white transition-colors duration-300 underline"
          >Obchodní podmínky</router-link
        >
        <router-link
          to="/zasady-ochrany-osobnich-udaju"
          class="text-sm sm:text-base hover:text-white transition-colors duration-300 underline"
          >Zásady ochrany osobních údajů</router-link
        >
      </div>

      <div class="flex flex-col space-y-4">
        <div class="flex items-center space-x-4">
          <img
            :src="require('@/assets/icons/socials/ig.png')"
            alt="Instagram"
            class="w-5 h-5 sm:w-4 sm:h-4"
          />
          <a
            href="https://instagram.com/pandabanditcz"
            target="_blank"
            class="hover:text-white transition-colors duration-300"
            >@pandabanditcz</a
          >
        </div>
        <div class="flex items-center space-x-4">
          <img
            :src="require('@/assets/icons/socials/fb.png')"
            alt="Facebook"
            class="w-5 h-5 sm:w-4 sm:h-4"
          />
          <a
            href="https://facebook.com/pandabanditLeather"
            target="_blank"
            class="hover:text-white transition-colors duration-300"
            >/pandabanditLeather</a
          >
        </div>
        <div class="flex items-center space-x-4">
          <img
            :src="require('@/assets/icons/socials/mail.png')"
            alt="Email"
            class="w-5 h-5 sm:w-4 sm:h-4"
          />
          <a
            href="mailto:info@pandabandit.cz"
            class="hover:text-white transition-colors duration-300"
            >info@pandabandit.cz</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>
