<template>
  <div
    v-if="showBanner"
    class="fixed bottom-0 left-0 right-0 bg-gold py-1 px-4 flex items-center justify-between text-almost-white"
  >
    <p class="text-black text-sm">
      Na našem webu používáme soubory cookies, abychom zjistili, jak s webem
      zacházíte. Kliknutím na "Přijmout" vyjadřujete Váš souhlas s tímto
      použitím.
      <router-link
        to="/zasady-ochrany-osobnich-udaju"
        class="text-black underline hover:underline"
        >Zásady ochrany osobních údajů</router-link
      >
    </p>
    <div class="flex items-center">
      <button
        class="bg-black border-1 text-gold border-white font-bold py-1 m-1 px-4 text-xs rounded"
        @click="acceptCookies"
      >
        Přijmout
      </button>
      <button class="ml-4 text-black text-3xl" @click="closeBanner">
        &times;
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "CookiesBar",
  setup() {
    const showBanner = ref(false);

    onMounted(() => {
      const consent = localStorage.getItem("cookieConsent");
      if (consent !== "true") {
        showBanner.value = true;
      }
    });

    function acceptCookies() {
      localStorage.setItem("cookieConsent", "true");
      showBanner.value = false;
    }

    function closeBanner() {
      showBanner.value = false;
    }

    return {
      acceptCookies,
      closeBanner,
      showBanner,
    };
  },
});
</script>
