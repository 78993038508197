<template>
  <nav class="bg-black text-gold border-b border-gold shadow-lg">
    <div
      class="max-w-6xl mx-auto px-4 flex justify-between items-center py-3 md:py-4"
    >
      <!-- Logo Section -->
      <router-link to="/" class="flex items-center">
        <img
          :src="require('@/assets/logo/classic_big.png')"
          alt="Logo"
          class="h-12 w-12 md:h-20 md:w-20 mr-2"
        />
        <span class="font-bebas text-3xl md:text-4xl">Panda Bandit</span>
      </router-link>

      <!-- Mobile Menu Button -->
      <button @click="isMenuOpen = !isMenuOpen" class="md:hidden">
        <Bars3Icon v-if="!isMenuOpen" class="h-6 w-6" />
        <XMarkIcon v-else class="h-6 w-6" />
      </button>

      <!-- Desktop Navigation Items -->
      <div class="hidden md:flex items-center space-x-4">
        <router-link
          v-for="link in navLinks"
          :key="link.name"
          :to="link.url"
          class="py-2 px-3 text-gold hover:text-almost-white font-bebas text-base md:text-lg"
        >
          {{ link.name }}
        </router-link>
        <RegularButton
          class="px-0 md:px-2 text-sm"
          buttonText="Chci svůj výrobek"
          :isGold="true"
          to="/chci-svuj-vyrobek"
        />
      </div>
    </div>

    <!-- Mobile Navigation Items -->
    <div v-if="isMenuOpen" class="md:hidden">
      <div class="px-4 pt-2 pb-3 space-y-1">
        <router-link
          v-for="link in navLinks"
          :key="link.name"
          :to="link.url"
          class="block px-3 py-3 font-bebas rounded-md text-base font-medium text-gold hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gold"
          @click="isMenuOpen = false"
        >
          {{ link.name }}
        </router-link>
      </div>
      <div class="px-4 flex justify-center">
        <RegularButton
          buttonText="Chci svůj výrobek"
          :isGold="true"
          class="w-full md:w-1/2 mb-4"
          to="/chci-svuj-vyrobek"
        />
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref } from "vue";
import RegularButton from "@/components/RegularButton.vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

const isMenuOpen = ref(false);

const navLinks = ref([
  { name: "O Panda Bandit", url: "/#o_nas" },
  { name: "Proces výroby", url: "/proces-vyroby" },
  { name: "Produkty", url: "/produkty" },
  { name: "Péče o kůži", url: "/pece-o-kuzi" },
  { name: "Kontakty", url: "/kontakty" },
]);
</script>
