import { getImagePath } from "@/utils/imageUtils";
import { ref, reactive, onMounted, onUnmounted } from "vue";

export function useFullscreenGallery(
  initialImages: { image: string; hover?: string }[],
  bigImagePositions: number[],
  options: { enableKeyboardNavigation?: boolean } = {},
  context: __WebpackModuleApi.RequireContext,
  productId: string,
) {
  const fullscreenImage = ref<string | null>(null);
  const currentIndex = ref<number>(0);
  const isFullscreenVisible = ref<boolean>(false);

  const localImages = reactive(
    initialImages.map((image) => ({
      ...image,
      current: image.image,
    })),
  );

  function updateFullscreenImage(index: number) {
    try {
      const imagePath = getImagePath(
        context,
        localImages[index].current || localImages[index].image,
        false,
        productId,
      );
      fullscreenImage.value = imagePath;
    } catch (error) {
      console.error("Failed to update fullscreen image:", error);
      fullscreenImage.value = null;
    }
  }

  function openFullscreen(index: number) {
    currentIndex.value = index;
    updateFullscreenImage(index);
    isFullscreenVisible.value = true;
  }

  function closeFullscreen() {
    fullscreenImage.value = null;
    isFullscreenVisible.value = false;
  }

  function nextImage() {
    currentIndex.value = (currentIndex.value + 1) % localImages.length;
    updateFullscreenImage(currentIndex.value);
  }

  function prevImage() {
    currentIndex.value =
      (currentIndex.value - 1 + localImages.length) % localImages.length;
    updateFullscreenImage(currentIndex.value);
  }

  function swapImage(index: number, isHover: boolean) {
    const image = localImages[index];
    if (image?.hover) {
      image.current = isHover ? image.hover : image.image;
    }
  }

  function isBigImage(index: number): boolean {
    return bigImagePositions.includes(index);
  }

  function updateGalleryImages(newImages: { image: string; hover?: string }[]) {
    localImages.splice(
      0,
      localImages.length,
      ...newImages.map((image) => ({
        ...image,
        current: image.image,
      })),
    );
  }

  function handleKeydown(event: KeyboardEvent) {
    if (!fullscreenImage.value) return;
    switch (event.key) {
      case "ArrowRight":
        nextImage();
        break;
      case "ArrowLeft":
        prevImage();
        break;
      case "Escape":
        closeFullscreen();
        break;
    }
  }

  onMounted(() => {
    if (options.enableKeyboardNavigation) {
      window.addEventListener("keydown", handleKeydown);
    }
  });

  onUnmounted(() => {
    if (options.enableKeyboardNavigation) {
      window.removeEventListener("keydown", handleKeydown);
    }
  });

  return {
    fullscreenImage,
    currentIndex,
    localImages,
    isFullscreenVisible,
    openFullscreen,
    closeFullscreen,
    nextImage,
    prevImage,
    swapImage,
    isBigImage,
    updateGalleryImages,
  };
}
