<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
    <IdeaItem
      v-for="(item, index) in ideaItems"
      :key="index"
      :heading="item.heading"
      :text="item.text"
      :showArrow="true"
      :useWhiteDark="getUseWhiteDark(index)"
    >
      <template #icon>
        <img
          :src="getIconContext(item.icon)"
          alt="Icon"
          class="h-16 w-16 xl:ml-14"
        />
      </template>
    </IdeaItem>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from "vue";
import IdeaItem from "./IdeaItem.vue";

// Import the context for the icons
const iconContext = require.context(
  "@/assets/icons/pictograms",
  false,
  /\.svg$/,
);

const ideaItems = [
  {
    heading: "Nezávazná poptávka",
    text: "Máte-li nápad, vytvořte kliknutím na “Chci svůj výrobek” nezávaznou objednávku. Na míře promyšlenosti nezáleží!",
    icon: "bulb.svg",
  },
  {
    heading: "Vytvoření objednávky",
    text: "Na nezávaznou objednávku odpovím a společně vyladíme Vaši představu. Dostanete cenový odhad a grafický návrh.",
    icon: "msg.svg",
  },
  {
    heading: "Faktura a platba",
    text: "Po odsouhlasení finálního grafického návrhu Vám zašlu fakturu s finální cenou včetně platby za zvolenou dopravu. Potvrdím Vám přijetí platby.",
    icon: "coins.svg",
  },
  {
    heading: "Výroba a doručení",
    text: "Po potvrzení přijetí platby se pouštím do výroby. Dodávám do 4 týdnů, je-li to technicky možné, tak se lze domluvit i na dřívějším dodání.",
    icon: "box.svg",
  },
];

const screenWidth = ref(window.innerWidth);

const updateWidth = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});

const isFourColumnLayout = computed(() => screenWidth.value >= 768);
const isTwoColumnLayout = computed(
  () => screenWidth.value >= 640 && screenWidth.value < 768,
);

const getUseWhiteDark = (index: number) => {
  if (isFourColumnLayout.value) {
    return index % 2 === 0;
  } else if (isTwoColumnLayout.value) {
    return index % 4 === 0 || index % 4 === 3;
  } else {
    return index % 2 === 0;
  }
};

function getIconContext(icon: string): string {
  return iconContext(`./${icon}`);
}
</script>
