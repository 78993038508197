<template>
  <transition name="fade">
    <div
      v-if="props.visible"
      class="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center"
      @click="close"
    >
      <button
        v-if="props.showNavigation"
        class="absolute left-4 md:left-8 text-white text-2xl md:text-4xl font-bold"
        @click.stop="prev"
      >
        &larr;
      </button>
      <img
        :src="props.imageSrc || ''"
        class="max-w-full max-h-full cursor-pointer p-2"
        @click.stop="close"
        alt="Fullscreen"
      />
      <button
        v-if="props.showNavigation"
        class="absolute right-4 md:right-8 text-white text-2xl md:text-4xl font-bold"
        @click.stop="next"
      >
        &rarr;
      </button>
      <button
        class="absolute top-4 right-4 md:top-8 md:right-8 text-white text-2xl md:text-4xl font-bold"
        @click.stop="close"
      >
        &times;
      </button>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  imageSrc: {
    type: String,
    required: true,
  },
  showNavigation: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["close", "prev", "next"]);

const close = () => {
  emit("close");
};

const prev = () => {
  emit("prev");
};

const next = () => {
  emit("next");
};
</script>
