<template>
  <div class="grid grid-cols-1 sm:grid-cols-2">
    <IdeaItem
      v-for="(item, index) in ideaItems"
      :key="index"
      :heading="item.heading"
      :text="item.text"
      :showArrow="false"
      :useWhiteDark="getUseWhiteDark(index)"
    >
      <template #icon>
        <img
          :src="getIconPath(item.icon)"
          alt="Icon"
          class="h-12 w-12 sm:h-16 sm:w-16"
        />
      </template>
    </IdeaItem>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from "vue";
import IdeaItem from "./IdeaItem.vue";

// Import the context for the icons
const iconContext = require.context(
  "@/assets/icons/pictograms",
  false,
  /\.svg$/,
);

const ideaItems = [
  {
    heading: "Design",
    text: "Každý vyrytý vzor nejdříve vznikne v náčrtníku na specifické přání zákazníka, stejně jako model výrobku",
    icon: "design.svg",
  },
  {
    heading: "Ruční výroba",
    text: "Od vyřezání jednotlivých součástí z bloku kůže přes rytí, barvení, šití a závěrečné vyleštění hran se jedná o čistě ruční práci",
    icon: "handmade.svg",
  },
  {
    heading: "Materiál",
    text: "Kůže pochází ze zvířat a je přirozeně nerovnoměrná a nesourodá,po nabarvení kůže vznikne zcela unikátní kresba pro každý centimetr",
    icon: "material.svg",
  },
  {
    heading: "Kvalita",
    text: "Udržitelnost je důležitá, proto odmítám vyhazovat části kůže s vadou kresby a zároveň mířím k dlouholeté výdrži výrobku díky kvalitnímu zpracovánía materiálu",
    icon: "quality.svg",
  },
];

const screenWidth = ref(window.innerWidth);

const updateWidth = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});

const isTwoColumnLayout = computed(() => {
  return screenWidth.value >= 640;
});

const getUseWhiteDark = (index: number) => {
  if (isTwoColumnLayout.value) {
    // Zig-zag pattern for 2x2 grid
    return index % 4 === 0 || index % 4 === 3;
  } else {
    // Alternating pattern for 1x4 grid
    return index % 2 === 0;
  }
};

function getIconPath(iconName: string): string {
  return iconContext(`./${iconName}`);
}
</script>
