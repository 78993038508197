<template>
  <component
    :is="props.to ? 'router-link' : 'button'"
    :to="props.to"
    :class="buttonClasses"
  >
    {{ props.buttonText }}
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  buttonText: {
    type: String,
    required: true,
  },
  isGold: {
    type: Boolean,
    default: false,
  },
  to: {
    type: String,
    default: null,
  },
});

const buttonClasses = computed(() => [
  props.isGold
    ? "bg-gold text-almost-white border-white hover:border-gold hover:bg-dark-gray"
    : "bg-dark-gray text-gold border-gold hover:border-dark-gray hover:bg-gold hover:text-almost-white",
  props.to ? "flex items-center justify-center" : "",
  "font-lexend text-base py-3 px-10 rounded-full border-2",
  "transition-colors duration-300 font-bold",
  "text-center",
]);
</script>
