<template>
  <div class="flex flex-col items-center space-y-4 my-12">
    <div class="flex items-center justify-center w-full px-8">
      <div class="w-1/6 max-w-xs border-t-2 border-gold"></div>

      <h2
        class="text-2xl md:text-4xl font-semibold text-almost-white uppercase font-bebas mx-8 break-keep"
      >
        {{ props.heading }}
      </h2>

      <div class="w-1/6 max-w-xs border-t-2 border-gold"></div>
    </div>
    <p v-if="hasText" class="text-center text-lg text-almost-white px-8">
      {{ props.text }}
    </p>
    <img
      v-if="showLogo"
      :src="require('@/assets/logo/classic_small.png')"
      alt="Logo"
      class="h-6 w-6"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    default: "",
  },
  logo: {
    type: Boolean,
    default: false,
  },
});

const hasText = computed(() => props.text.length > 0);
const showLogo = computed(() => props.logo);
</script>
