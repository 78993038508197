<template>
  <div class="p-4 flex flex-col items-center py-8 text-white">
    <div class="text-gold font-lexend text-2xl mb-0 font-bold">
      {{ sectionNumber }}
    </div>
    <h2 class="font-lexend text-2xl mb-4 text-gold font-bold">{{ heading }}</h2>
    <ol
      class="list-decimal list-inside text-left w-full max-w-4xl font-work-sans text-lg font-light"
    >
      <li v-for="(item, index) in items" :key="index" class="mb-4">
        {{ item.text }}
        <ul v-if="item.subItems" class="list-disc list-inside ml-6 mt-2">
          <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
            {{ subItem }}
          </li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SectionWithNumber",
  props: {
    sectionNumber: {
      type: String as PropType<string>,
      required: true,
    },
    heading: {
      type: String as PropType<string>,
      required: true,
    },
    items: {
      type: Array as PropType<Array<{ text: string; subItems?: string[] }>>,
      required: true,
    },
  },
});
</script>
