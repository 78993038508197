<template>
  <HeaderSplitter
    heading="Zásady&nbsp;ochrany&nbsp;osobních&nbsp;údajů"
    :logo="false"
  />
  <div class="md:container mx-auto md:px-32 pb-8">
    <LawSection
      v-for="section in lawSectionsData"
      :key="section.sectionNumber"
      :sectionNumber="section.sectionNumber"
      :heading="section.heading"
      :items="section.items"
    />
    <p class="text-center text-almost-white text-lg font-light font-work-sans">
      Tyto podmínky nabývají účinnosti dnem 26.6.2022.
    </p>
  </div>
</template>

<script lang="ts" setup>
import HeaderSplitter from "@/components/HeaderSplitter.vue";
import LawSection from "@/components/LawSection.vue";
import rulesAndPersonalData from "@/data/rulesAndPersonalData.json";
const lawSectionsData = rulesAndPersonalData;
</script>
