<template>
  <router-link :to="props.link" class="block relative">
    <div class="w-full h-full relative overflow-hidden">
      <ProgressiveImage
        :placeholder="getImagePath(context, props.backgroundImage, true)"
        :image="getImagePath(context, props.backgroundImage)"
        class="absolute inset-0 w-full h-full object-cover"
      />
      <div class="absolute inset-0 bg-black bg-opacity-30"></div>
      <div
        class="relative h-full flex flex-col justify-between transition-transform duration-300 hover:scale-105 md:hover:scale-110"
      >
        <h2
          class="px-8 pt-8 md:px-20 md:pt-20 text-gold text-3xl md:text-5xl text-center font-bebas"
        >
          {{ props.text }}
        </h2>
        <div class="px-8 pb-8 pt-8 md:px-20 md:pb-20 flex justify-center">
          <RegularButton
            class="text-xs md:text-sm w-full min-w-[10rem] max-w-xs"
            buttonText="O&nbsp;produktu"
            :isGold="true"
            :to="props.link"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import ProgressiveImage from "@/components/ProgressiveImage.vue";
import RegularButton from "@/components/RegularButton.vue";
import { getImagePath } from "@/utils/imageUtils";

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
});

const context = require.context("@/assets/images/product", true, /\.jpg$/);
</script>
