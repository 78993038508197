import {
  createRouter,
  createWebHistory,
  RouteMeta,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProcessView from "../views/ProcessView.vue";
import ProductsView from "../views/ProductsView.vue";
import LeatherCareView from "../views/LeatherCareView.vue";
import ContactsView from "../views/ContactsView.vue";
import CreateMyProductView from "../views/CreateMyProductView.vue";
import TermsAndConditionsView from "../views/TermsAndConditionsView.vue";
import RulesPersonalDataView from "../views/RulesPersonalDataView.vue";
// import QuestionView from "../views/QuestionView.vue";
import OrderView from "../views/OrderView.vue";
import ProductView from "../views/ProductView.vue";
interface CustomRouteMeta extends RouteMeta {
  title: string;
  description: string;
}
const routes: Array<RouteRecordRaw & { meta: CustomRouteMeta }> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Domů - Panda Bandit",
      description:
        "Výroba uměleckých zakázkových doplňků z kůže. Mezi hodnoty Panda Bandit se řadí personalizované výrobky, ruční výroba a originálně vyryté vzory. Každý výrobek je kvalitní originál. Kůže pochází ze zvířat a je přirozeně nerovnoměrná a nesourodá, po nabarvení kůže vznikne zcela unikátní kresba pro každý centimetr.",
    },
  },
  {
    path: "/proces-vyroby",
    name: "process",
    component: ProcessView,
    meta: {
      title: "Proces Výroby - Panda Bandit",
      description:
        "Proces výroby zakázkových kožených výrobků Vám popíši v několika krocích. ",
    },
  },
  {
    path: "/produkty",
    name: "products",
    component: ProductsView,
    meta: {
      title: "Naše Produkty - Panda Bandit",
      description:
        "Inspirace z oblíbených i netradičních kožených výrobků. Pokud byste nenašli to, co hledáte ani teď, ráda Vám poradím.",
    },
  },
  {
    path: "/pece-o-kuzi",
    name: "leatherCare",
    component: LeatherCareView,
    meta: {
      title: "Péče o Kůži - Panda Bandit",
      description: "Zjistěte jak se co nejlépe starat o své kožené výrobky.",
    },
  },
  {
    path: "/kontakty",
    name: "contacts",
    component: ContactsView,
    meta: {
      title: "Kontaktujte Nás - Panda Bandit",
      description: "Kontakty Panda Bandit. Email: info@pandabandit.cz",
    },
  },
  {
    path: "/chci-svuj-vyrobek",
    name: "createMyProduct",
    component: CreateMyProductView,
    meta: {
      title: "Vytvořte Svůj Produkt - Panda Bandit",
      description:
        "Výroba uměleckých zakázkových doplňků z kůže. Mezi hodnoty Panda Bandit se řadí personalizované výrobky, ruční výroba a originálně vyryté vzory. Každý výrobek je kvalitní originál. Kůže pochází ze zvířat a je přirozeně nerovnoměrná a nesourodá, po nabarvení kůže vznikne zcela unikátní kresba pro každý centimetr.",
    },
  },
  {
    path: "/obchodni-podminky",
    name: "termsAndConditions",
    component: TermsAndConditionsView,
    meta: {
      title: "Obchodní Podmínky - Panda Bandit",
      description: "Obchodní podmínky Panda Bandit",
    },
  },
  {
    path: "/zasady-ochrany-osobnich-udaju",
    name: "rulesPersonalDataView",
    component: RulesPersonalDataView,
    meta: {
      title: "Zásady Ochrany Osobních Údajů - Panda Bandit",
      description:
        "Kompletní seznam zásad ochrany osobních údajů Panda Bandit. ",
    },
  },
  // {
  //   path: "/dotaz",
  //   name: "questionView",
  //   component: QuestionView,
  //   meta: {
  //     title: "Položit Dotaz - Panda Bandit",
  //     description:
  //       "Výroba uměleckých zakázkových doplňků z kůže. Mezi hodnoty Panda Bandit se řadí personalizované výrobky, ruční výroba a originálně vyryté vzory. Každý výrobek je kvalitní originál. Kůže pochází ze zvířat a je přirozeně nerovnoměrná a nesourodá, po nabarvení kůže vznikne zcela unikátní kresba pro každý centimetr.",
  //   },
  // },
  {
    path: "/nezavazna-poptavka",
    name: "orderView",
    component: OrderView,
    meta: {
      title: "Nezávazná Poptávka - Panda Bandit",
      description:
        "Výroba uměleckých zakázkových doplňků z kůže. Mezi hodnoty Panda Bandit se řadí personalizované výrobky, ruční výroba a originálně vyryté vzory. Každý výrobek je kvalitní originál. Kůže pochází ze zvířat a je přirozeně nerovnoměrná a nesourodá, po nabarvení kůže vznikne zcela unikátní kresba pro každý centimetr.",
    },
  },
  {
    path: "/produkt/:productName",
    name: "productView",
    component: ProductView,
    meta: {
      title: "Detail Produktu - Panda Bandit",
      description:
        "Inspirace z oblíbených i netradičních kožených výrobků. Pokud byste nenašli to, co hledáte ani teď, ráda Vám poradím.",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if there is a hash, this means an anchor link is being used
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 70, // you can add top offset here if your site has a fixed header
      };
    }
    // for regular route navigation, you can return to the top or saved position
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Check if the title exists and is a string; otherwise, use a default title
  const defaultTitle = "Panda Bandit";
  document.title =
    typeof to.meta.title === "string" ? to.meta.title : defaultTitle;

  const meta = to.meta as CustomRouteMeta | undefined;

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      meta?.description || "Default description",
    );
  }

  next();
});

export default router;
