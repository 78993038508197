<template>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-3 p-4 my-4">
    <div
      v-for="(image, index) in localImages"
      :key="index"
      class="overflow-hidden shadow-lg aspect-w-9 aspect-h-16 cursor-pointer"
    >
      <ProgressiveImage
        :placeholder="getImagePath(context, image.current, true, null)"
        :image="getImagePath(context, image.current, false, null)"
        @click="handleOpenFullscreen(index)"
        class="w-full h-full object-cover object-center overflow-hidden transition-transform duration-300 ease-in-out hover:scale-105 bg-[#121212]"
      />
    </div>

    <FullscreenImageViewer
      :visible="isFullscreenVisible"
      :imageSrc="fullscreenImage || ''"
      @close="handleCloseFullscreen"
      @next="nextImage"
      @prev="prevImage"
    />
  </div>
</template>

<script lang="ts" setup>
import ProgressiveImage from "@/components/ProgressiveImage.vue";
import FullscreenImageViewer from "@/components/FullscreenImageViewer.vue";
import { useFullscreenGallery } from "@/composables/useFullscreenGallery";
import { getImagePath } from "@/utils/imageUtils";

const context = require.context("@/assets/images/work", true, /\.jpg$/);

const initialImages = [
  {
    image: "brasna",
    alt: "Description of Image 1",
  },
  {
    image: "mesec",
    alt: "Description of Image 2",
  },
  {
    image: "popruh",
    alt: "Kompletně ručně vyráběný kožený popruh na kytaru je jistě něco, co pomůže kytaristovi zdůraznit svůj image. Ať už se jedná o drsného rockera, který touží po naprosté jedinečnosti a svoji identitu sděluje jak svojí hudbou, tak každým jedním doplňkem, stejně tak vlastní kožený popruh ozdobí i akustickou kytaru a zaujme na první pohled.",
  },
];

const {
  fullscreenImage,
  openFullscreen,
  closeFullscreen,
  nextImage,
  prevImage,
  updateGalleryImages,
  localImages,
  isFullscreenVisible,
} = useFullscreenGallery(
  initialImages,
  [],
  {
    enableKeyboardNavigation: true,
  },
  context,
  "",
);

updateGalleryImages(initialImages);

function handleOpenFullscreen(index: number) {
  openFullscreen(index);
}

function handleCloseFullscreen() {
  closeFullscreen();
}
</script>
