<template>
  <HeaderSplitter heading="Obchodní&nbsp;podmínky" text="" :logo="false" />
  <div class="md:container mx-auto md:px-32 pb-8">
    <TextSection
      v-for="section in sections"
      :key="section.heading"
      :heading="section.heading"
      :content="section.content"
    />
  </div>
</template>

<script lang="ts" setup>
import HeaderSplitter from "@/components/HeaderSplitter.vue";
import TextSection from "@/components/TextSection.vue";
import termsAndConditions from "@/data/termsAndConditions.json";
const sections = termsAndConditions;
</script>
