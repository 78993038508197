import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4 bg-dark-gray text-almost-white" }
const _hoisted_2 = { class: "font-lexend text-gold text-xl font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.heading), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (paragraph, index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: index,
        class: _normalizeClass([{ 'pb-4': index < _ctx.content.length - 1 }, "font-work-sans text-lg font-light"])
      }, _toDisplayString(paragraph), 3))
    }), 128))
  ]))
}