<template>
  <div class="relative overflow-hidden h-full max-h-[600px]">
    <div class="flex overflow-hidden h-full max-h-[600px]">
      <!-- Carousel Slides -->
      <div
        class="flex transition-transform ease-in-out duration-500 w-screen"
        :style="{
          transform: `translateX(-${currentSlideIndex.valueOf() * 100}%)`,
        }"
      >
        <div
          class="flex-none w-full h-full relative"
          v-for="(slide, index) in slides"
          :key="index"
        >
          <ProgressiveImage
            :placeholder="getImagePath(context, slide.image, true)"
            :image="getImagePath(context, slide.image, false)"
            :alt="`Slide ${index + 1}`"
            class="h-full w-full object-cover object-center"
          />

          <!-- Text container -->
          <div
            class="absolute bottom-4 md:bottom-40 left-4 right-4"
            :class="{
              'md:left-96': slide.position === 'right',
              'md:right-96': slide.position === 'left',
            }"
          >
            <div class="text-center">
              <h3
                class="text-3xl md:text-5xl tracking-wider text-gold font-bebas text-shadow mb-2 md:mb-4"
              >
                {{ slide.subtitle }}
              </h3>
              <h2
                class="text-5xl md:text-7xl tracking-wider text-almost-white uppercase font-bebas text-shadow break-words"
              >
                {{ slide.title }}
              </h2>
              <RegularButton
                :buttonText="slide.buttonText"
                :isGold="false"
                @click="redirectTo(slide.buttonUrl)"
                class="mt-2 md:mt-4 text-base md:text-xl inline-block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Arrows for navigation -->
    <button
      @click="prevSlide"
      class="absolute top-1/2 left-2 transform -translate-y-1/2 z-20"
    >
      <ChevronLeftIcon class="h-6 w-6 text-almost-white shadow" />
    </button>
    <button
      @click="nextSlide"
      class="absolute top-1/2 right-2 transform -translate-y-1/2 z-20"
    >
      <ChevronRightIcon class="h-6 w-6 text-almost-white shadow" />
    </button>

    <!-- Indicators -->
    <div
      class="hidden md:flex absolute bottom-5 left-1/2 transform -translate-x-1/2 space-x-2"
    >
      <div
        v-for="(slide, index) in slides"
        :key="`indicator-${index}`"
        :class="[
          'h-2 w-2 rounded-full',
          currentSlideIndex === index ? 'bg-gold' : 'bg-white opacity-50',
        ]"
        @click="goToSlide(index)"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";
import RegularButton from "@/components/RegularButton.vue";
import ProgressiveImage from "@/components/ProgressiveImage.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import router from "@/router";
import { getImagePath } from "@/utils/imageUtils";

const context = require.context("@/assets/images/carousel", true, /\.jpg$/);

const currentSlideIndex = ref(0);
const slides = ref([
  {
    image: "pouzdro_bryle",
    title: "Ideální dárek",
    subtitle: "Kožené doplňky na zakázku?",
    position: "left",
    buttonText: "Chci výrobek",
    buttonUrl: "/chci-svuj-vyrobek",
  },
  {
    image: "penezenka",
    title: "Vlastní design",
    subtitle: "Vlastní kožený výrobek",
    position: "left",
    buttonText: "Jak na to?",
    buttonUrl: "#jak_na_to",
  },
  {
    image: "pouzdro",
    title: "Výrobky z kůže na zakázku",
    subtitle: "",
    position: "right",
    buttonText: "Produkty",
    buttonUrl: "/produkty",
  },
]);

const redirectTo = (url: string) => {
  router.push(url);
};

const nextSlide = () => {
  currentSlideIndex.value = (currentSlideIndex.value + 1) % slides.value.length;
};

const prevSlide = () => {
  currentSlideIndex.value =
    (currentSlideIndex.value - 1 + slides.value.length) % slides.value.length;
};

const goToSlide = (index: number) => {
  currentSlideIndex.value = index;
};

let slideInterval: number;

onMounted(() => {
  slideInterval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
});

onUnmounted(() => {
  clearInterval(slideInterval);
});
</script>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
