import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col items-center py-8 text-white" }
const _hoisted_2 = { class: "text-gold font-lexend text-2xl mb-0 font-bold" }
const _hoisted_3 = { class: "font-lexend text-2xl mb-4 text-gold font-bold" }
const _hoisted_4 = { class: "list-decimal list-inside text-left w-full max-w-4xl font-work-sans text-lg font-light" }
const _hoisted_5 = {
  key: 0,
  class: "list-disc list-inside ml-6 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.sectionNumber), 1),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.heading), 1),
    _createElementVNode("ol", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "mb-4"
        }, [
          _createTextVNode(_toDisplayString(item.text) + " ", 1),
          (item.subItems)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subItems, (subItem, subIndex) => {
                  return (_openBlock(), _createElementBlock("li", { key: subIndex }, _toDisplayString(subItem), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}