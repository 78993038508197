<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4 my-4">
    <div
      v-for="(image, index) in localImages"
      :key="index"
      :class="{
        'col-span-1 lg:col-span-2 row-span-1 h-96': isBigImage(index),
        'col-span-1 row-span-1 h-96': !isBigImage(index),
      }"
      class="overflow-hidden w-full h-96 cursor-pointer"
    >
      <ProgressiveImage
        :placeholder="getImagePath(context, image.current, true, null)"
        :image="getImagePath(context, image.current, false, null)"
        @mouseenter="() => swapImage(index, true)"
        @mouseleave="() => swapImage(index, false)"
        @click="handleOpenFullscreen(index)"
        class="w-full h-full object-cover object-center overflow-hidden transition-transform duration-300 ease-in-out hover:scale-105 bg-[#121212]"
      />
    </div>
    <FullscreenImageViewer
      :visible="isFullscreenVisible"
      :imageSrc="fullscreenImage || ''"
      @close="handleCloseFullscreen"
      @next="nextImage"
      @prev="prevImage"
    />
  </div>
</template>

<script lang="ts" setup>
import ProgressiveImage from "@/components/ProgressiveImage.vue";
import FullscreenImageViewer from "@/components/FullscreenImageViewer.vue";
import { useFullscreenGallery } from "@/composables/useFullscreenGallery";
import { getImagePath } from "@/utils/imageUtils";

const props = defineProps<{
  images: { image: string; hover: string }[];
  bigImagePositions: number[];
}>();

const context = require.context("@/assets/images", true, /\.jpg$/);

const {
  fullscreenImage,
  openFullscreen,
  closeFullscreen,
  nextImage,
  prevImage,
  swapImage,
  updateGalleryImages,
  localImages,
  isBigImage,
  isFullscreenVisible,
} = useFullscreenGallery(
  props.images,
  props.bigImagePositions,
  {
    enableKeyboardNavigation: true,
  },
  context,
  "",
);

updateGalleryImages(props.images);

function handleOpenFullscreen(index: number) {
  openFullscreen(index);
}

function handleCloseFullscreen() {
  closeFullscreen();
}
</script>

<style scoped>
.grid > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
