import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-05c3a8a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.currentSrc,
    alt: _ctx.alt,
    class: _normalizeClass([_ctx.imageClass, { 'blur-effect': _ctx.isLoading, loaded: !_ctx.isLoading }]),
    style: _normalizeStyle({
      transition: !_ctx.isLoading ? `filter ease ${_ctx.transitionDuration}` : '',
    }),
    ref: "imageRef"
  }, null, 14, _hoisted_1))
}