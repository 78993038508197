<template>
  <div class="md:container mx-auto md:px-32 pb-4">
    <HeaderSplitter
      heading="Proč&nbsp;pečovat&nbsp;o&nbsp;kůži"
      text="Všechny produkty, které vyjdou z mojí dílny, mají potenciál vydržet a k tomu krásné roky, možná i desítky let. K tomu je ovšem nutné jim věnovat patřičnou péči. 
      
      Zisk patiny se sice obvykle považuje za pozitivní vlastnost koženého výrobku, nicméně pravá kůže je velmi citlivá k okolním vlivům jako jsou tekutiny, slunce nebo prosté mechanické poškození, které mohou vést k výraznému zkrácení životnosti výrobku."
      :logo="false"
    />
    <NumberedSquare
      v-for="instruction in leatherCareData"
      :key="instruction.number"
      :number="instruction.number"
      :heading="instruction.heading"
      :text="instruction.text"
      class="my-12"
    />
    <div class="flex justify-center px-8">
      <p class="text-gold text-2xl py-16 mt-4 font-bold">
        Chcete-li zboží reklamovat nebo máte zájem o servisní opravy výrobku,
        přečtěte si příslušnou část
        <router-link to="/obchodni-podminky" class="underline">
          obchodních podmínek</router-link
        >
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NumberedSquare from "@/components/NumberedSquare.vue";
import HeaderSplitter from "@/components/HeaderSplitter.vue";
import leatherCare from "@/data/leatherCare.json";
const leatherCareData = leatherCare;
</script>
