import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed bottom-0 left-0 right-0 bg-gold py-1 px-4 flex items-center justify-between text-almost-white"
}
const _hoisted_2 = { class: "text-black text-sm" }
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showBanner)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(" Na našem webu používáme soubory cookies, abychom zjistili, jak s webem zacházíte. Kliknutím na \"Přijmout\" vyjadřujete Váš souhlas s tímto použitím. "),
          _createVNode(_component_router_link, {
            to: "/zasady-ochrany-osobnich-udaju",
            class: "text-black underline hover:underline"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Zásady ochrany osobních údajů")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "bg-black border-1 text-gold border-white font-bold py-1 m-1 px-4 text-xs rounded",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.acceptCookies && _ctx.acceptCookies(...args)))
          }, " Přijmout "),
          _createElementVNode("button", {
            class: "ml-4 text-black text-3xl",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeBanner && _ctx.closeBanner(...args)))
          }, " × ")
        ])
      ]))
    : _createCommentVNode("", true)
}