<template>
  <div
    class="bg-dark-gray text-almost-white flex justify-center items-center p-4"
  >
    <div
      class="max-w-2xl w-full flex flex-col md:flex-row items-center md:items-start text-center md:text-left gap-4 md:gap-8"
    >
      <div class="md:flex">
        <img
          :src="require('@/assets/logo/classic_big.png')"
          alt="Panda Bandit Logo"
          class="h-32 w-32 md:h-full md:w-full"
        />
      </div>
      <div class="flex-grow">
        <div class="mb-8">
          <h2 class="text-6xl font-bold font-bebas text-white">
            Panda&nbsp;Bandit
          </h2>
          <h3 class="text-2xl text-gold font-bold">Eliška Málková</h3>
        </div>
        <div class="text-xl">
          <span class="font-lexend">
            <span class="text-gold">Email:</span> info@pandabandit.cz
          </span>
          <div class="font-lexend grid">
            <span class="text-gold">Sídlo:</span><span> Moskevská 449/40</span>
            <span class="font-lexend">101 00 Praha 10</span>
            <span class="font-lexend">Česká republika</span>
          </div>
        </div>
        <div class="flex justify-center md:justify-start space-x-4 mt-4">
          <a href="https://www.facebook.com/PandaBanditLeather" target="_blank">
            <img
              :src="require('@/assets/icons/socials/fb.png')"
              alt="Facebook"
              class="h-8 w-8"
            />
          </a>
          <a href="https://www.instagram.com/pandabanditcz/" target="_blank">
            <img
              :src="require('@/assets/icons/socials/ig.png')"
              alt="Instagram"
              class="h-8 w-8"
            />
          </a>
          <a href="mailto:info@pandabandit.cz" target="_blank">
            <img
              :src="require('@/assets/icons/socials/mail.png')"
              alt="Mail"
              class="h-8 w-8"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
