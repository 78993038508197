export function getImagePath(
  context: __WebpackModuleApi.RequireContext,
  imageName: string,
  isPlaceholder = false,
  productId: string | null = null,
): string {
  const path = productId
    ? `./${productId}/${imageName}${isPlaceholder ? "_M" : ""}.jpg`
    : `./${imageName}${isPlaceholder ? "_M" : ""}.jpg`;

  try {
    return context(path);
  } catch (e) {
    console.error(`Image not found: ${path}`, e);
    return "";
  }
}
