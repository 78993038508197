<template>
  <img
    :src="currentSrc"
    :alt="alt"
    :class="[imageClass, { 'blur-effect': isLoading, loaded: !isLoading }]"
    :style="{
      transition: !isLoading ? `filter ease ${transitionDuration}` : '',
    }"
    ref="imageRef"
  />
</template>

<script lang="ts">
import { ref, onMounted, onUnmounted, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    placeholder: {
      type: String as PropType<string>,
      required: true,
    },
    image: {
      type: String as PropType<string>,
      required: true,
    },
    alt: {
      type: String as PropType<string>,
      default: "",
    },
    imageClass: {
      type: String as PropType<string>,
      default: "",
    },
    transitionDuration: {
      type: String as PropType<string>, // Accepts CSS time format like '0s', '0.5s', etc.
      default: "0.5s", // Default transition duration for the loaded class
    },
  },
  setup(props) {
    const currentSrc = ref(props.placeholder); // Start with the placeholder image
    const isLoading = ref(true);
    const imageRef = ref<HTMLElement | null>(null);

    // Function to load the full-resolution image
    const loadImage = (src: string) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        currentSrc.value = src;
        isLoading.value = false; // Remove blur when the image is fully loaded
      };
    };

    // IntersectionObserver callback
    const onIntersect = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        loadImage(props.image); // Load the full image when the placeholder is in view
      }
    };

    let observer: IntersectionObserver;

    onMounted(() => {
      // Set up the IntersectionObserver
      observer = new IntersectionObserver(onIntersect, {
        rootMargin: "50px", // Load image slightly before it enters the viewport
        threshold: 0.1, // Trigger when 10% of the image is visible
      });

      if (imageRef.value) {
        observer.observe(imageRef.value);
      }
    });

    onUnmounted(() => {
      if (observer && imageRef.value) {
        observer.unobserve(imageRef.value);
      }
    });

    return {
      currentSrc,
      isLoading,
      imageRef,
    };
  },
});
</script>

<style scoped>
.blur-effect {
  filter: blur(10px);
  transition: filter ease 0.5s;
}

.loaded {
  filter: none;
}
</style>
