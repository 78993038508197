<template>
  <div>
    <div
      v-for="(section, index) in props.sections"
      :key="index"
      class="flex flex-col md:flex-row bg-dark-gray text-almost-white my-16 items-center"
    >
      <!-- Image Section -->
      <div
        :class="{ 'md:order-last': section.imageRight }"
        class="md:flex-1 overflow-hidden w-full cursor-pointer"
      >
        <ProgressiveImage
          :placeholder="getImagePath(context, section.image, true)"
          :image="getImagePath(context, section.image, false)"
          alt="Illustrative image"
          class="w-full transition-transform duration-300 ease-in-out hover:scale-110 bg-[#121212]"
          @click="handleOpenFullscreen(index)"
        />
      </div>
      <!-- Text Section -->
      <div
        :class="{ 'md:order-first': section.imageRight }"
        class="md:flex-1 p-4 flex flex-col justify-center items-center w-full"
      >
        <div class="flex items-center space-x-4">
          <img
            :src="getLogoPath('classic_small')"
            alt="Logo"
            class="w-10 h-10"
          />
          <h2 class="text-xl font-lexend text-gold font-bold">
            {{ section.title }}
          </h2>
        </div>
        <p class="font-work-sans text-lg font-light text-center mt-3">
          {{ section.text }}
        </p>
      </div>
    </div>

    <!-- Fullscreen Image Viewer -->
    <FullscreenImageViewer
      :visible="isFullscreenVisible"
      :imageSrc="fullscreenImage || ''"
      @close="handleCloseFullscreen"
      @next="nextImage"
      @prev="prevImage"
    />
  </div>
</template>

<script lang="ts" setup>
import ProgressiveImage from "@/components/ProgressiveImage.vue";
import FullscreenImageViewer from "@/components/FullscreenImageViewer.vue";
import { useFullscreenGallery } from "@/composables/useFullscreenGallery";
import { getImagePath } from "@/utils/imageUtils";
import { SectionData } from "@/types/SectionData";

const props = defineProps<{
  sections: SectionData[];
}>();

const context = require.context("@/assets/images/process", true, /\.jpg$/);

const images = props.sections.map((section) => ({ image: section.image }));

const {
  fullscreenImage,
  openFullscreen,
  closeFullscreen,
  nextImage,
  prevImage,
  isFullscreenVisible,
} = useFullscreenGallery(
  images,
  [],
  { enableKeyboardNavigation: true },
  context,
  "",
);

function handleOpenFullscreen(index: number) {
  openFullscreen(index);
}

function handleCloseFullscreen() {
  closeFullscreen();
}

function getLogoPath(logoName: string): string {
  const logosContext = require.context("@/assets/logo", false, /\.png$/);
  return logosContext(`./${logoName}.png`);
}
</script>
